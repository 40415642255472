import React from 'react'
import PropTypes from 'prop-types'

import HaasGIF from './Home/images/haas-gif.webp'

import SecondaryPage from '../Components/SecondaryPage'

const iframeSrc = 'https://www.youtube.com/embed/vEBcMmbNuI4'

const content = (
  <div>
    <p className="mb-4">
      Our CNC machines are equipped with advanced computer numerical control technology, allowing us to produce intricate and complex parts with unparalleled precision and efficiency. Whether it's turning, facing, threading, or drilling, our CNC lathe excels in delivering high-quality results for your projects.
    </p>

    <p className="mb-4">
      Our CNC mill is equally adept at 3D contouring, pocketing, and profiling, making it the perfect solution for creating intricate designs and prototypes. With our team of skilled technicians and cutting-edge equipment, we take your ideas and turn them into reality, ensuring the utmost accuracy and consistency for every project.
    </p>

    <p>
      With our CNC lathe and mill services, we cater to a wide range of industries, from aerospace and automotive to electronics and medical equipment manufacturing. The combination of computer precision and our team's expertise enables us to meet the most demanding requirements and deliver results that exceed expectations. From small-scale production to large quantities, we have the capacity to handle projects of all sizes with speed and accuracy.
    </p>
  </div>
)


export default function CNCLatheAndMill() {
  return (
    <div>
      <SecondaryPage
        banner={ HaasGIF }
        content={ content }
        video={ iframeSrc }
        title="CNC Lathe & Mill"
      />
    </div>
  )
}

CNCLatheAndMill.propTypes = {}
