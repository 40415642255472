import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import MobileNavigation from './MobileNavigation';
import Logo from './Logo';

function Nav() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <nav className="bg-black flex items-center justify-between py-4">
      <div className="flex items-center justify-center w-full ml-8">
        <Logo className="h-24" />
      </div>

      <div>
        {/* Hamburger Menu Icon */}
        {isMobile && (
          <button
            onClick={toggleMobileNav}
            className={`text-white focus:outline-none md:hidden text-2xl ml-auto mr-4 ${isMobileNavOpen ? 'text-black' : ''}`}
          >
            {isMobileNavOpen ? (
              <span>&#x2715;</span>
            ) : (
              <span>&#x2630;</span>
            )}
          </button>
        )}
      </div>

      {isMobile && isMobileNavOpen && <MobileNavigation toggleNav={toggleMobileNav} isOpen={isMobileNavOpen} />}
    </nav>
  );
}

export default Nav;
