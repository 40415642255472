import { useEffect } from 'react'
import { Outlet, useNavigate } from "react-router-dom"

import '../App.css'

import Nav from '../Components/Nav'


function Root() {
  const navigate = useNavigate()

  useEffect(() => {
    if ( true ) {
      navigate('home')
    }
  }, [navigate])

  return (
    <>
      <Nav />

      <div id="detail">
        <Outlet />
      </div>
    </>
  )
}

export default Root
