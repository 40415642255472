import React from 'react'
import PropTypes from 'prop-types'

import AS9100 from './components/AS9100'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import HeroBanner from './components/HeroBanner'
import LiveDemo from './components/LiveDemo'
import OurServices from './components/OurServices'


export default function Home() {
    return (
        <div>
            <HeroBanner />

            <AS9100
                className="h-20"
            />

            <OurServices />

            <LiveDemo />

            <ContactForm />

            <Footer />
        </div>
    )
}

Home.propTypes = {}
