import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // If using React Router for navigation

export default function MobileNavigation({ isOpen, toggleNav }) {
  return (
    <div className="mobile-nav z-50">
      <button className="menu-toggle bg-[#B39A5B] text-white px-4 py-2 rounded" onClick={toggleNav}>
        MENU
      </button>

      <div className={`nav-menu ${isOpen ? 'open' : ''} bg-[#f4f4f4] fixed top-0 right-0 bottom-0 w-64 p-4 transition-transform duration-300 ease-in-out z-10 overflow-y-auto`}>
        <div className="nav-items mb-6">
          <Link to="/home" onClick={toggleNav} className="nav-item block font-bold py-2">
            Home
          </Link>
          <Link to="/cnc-lathe-and-mill" onClick={toggleNav} className="nav-item block font-bold py-2">
            CNC Lathe & Mill
          </Link>
          <Link to="/welding" onClick={toggleNav} className="nav-item block font-bold py-2">
            Welding
          </Link>
          <Link to="/manual-lathe-and-mill" onClick={toggleNav} className="nav-item block font-bold py-2">
            Manual Lathe & Mill
          </Link>
        </div>
      </div>
    </div>
  );
}
