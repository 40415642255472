import React from 'react'

import AS9100Img from '../../../images/AS9100-no-bg.png'


export default function AS9100({ className }) {
    return null

    return (
        <div className="bg-gray-200 flex items-center justify-center py-12">
            <img
                alt="AS9100 Certified"
                className={ className }
                src={  AS9100Img }
            />
        </div>
    )
}
