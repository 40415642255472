import React from 'react'
import PropTypes from 'prop-types'

import WeldingGIF from './Home/images/welding.gif'

import SecondaryPage from '../Components/SecondaryPage'

const iframeSrc = 'https://www.youtube.com/embed/twUAa5LWUvk'
const secondIframeSrc = 'https://www.youtube.com/embed/elmDvqdeMKI'

const content = (
  <div>
    <p className="mb-4">
      <span className="text-lg font-bold">MIG (Metal Inert Gas) welding</span>, also known as Gas Metal Arc Welding (GMAW), is a widely used welding process that utilizes a continuously fed consumable wire electrode and a shielding gas to create an electric arc between the wire and the base metal.
    </p>

    <p className="mb-4">
      The shielding gas, typically a mixture of argon and carbon dioxide, protects the molten weld pool from atmospheric contamination, ensuring clean and strong welds. MIG welding is known for its ease of use, high welding speed, and versatility, making it suitable for a wide range of materials, including carbon steel, stainless steel, and aluminum.
    </p>

    <p className="mb-4">
      The process is commonly employed in automotive, fabrication, and manufacturing industries due to its efficiency and ability to handle both thick and thin materials.
    </p>
  </div>
)

const secondContent = (
  <div>
    <p className="mb-4">
      <span className="text-lg font-bold">Stick welding</span>, also known as Shielded Metal Arc Welding (SMAW), is a popular welding process that utilizes a consumable electrode coated in flux. When the electrode comes into contact with the base metal, the flux coating generates a protective shield around the arc, preventing impurities from contaminating the weld.
    </p>

    <p className="mb-4">
      Stick welding is characterized by its simplicity and suitability for outdoor and remote applications, as it does not require a separate shielding gas.
    </p>

    <p>
      It is commonly used in construction, maintenance, and repair work, especially in situations where the environment may be less controlled. While Stick welding offers excellent versatility and can be used on a variety of metals, it may require more skill and practice to achieve consistent and clean welds compared to MIG welding.
    </p>
  </div>
)


export default function MigAndStickWelding() {
  return (
    <div>
      <SecondaryPage
        banner={ WeldingGIF }
        content={ content }
        secondContent={ secondContent }
        video={ iframeSrc }
        secondVideo={ secondIframeSrc }
        title="Welding (MIG & Stick)"
      />
    </div>
  )
}

MigAndStickWelding.propTypes = {}
