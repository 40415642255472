import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../../../Components/Logo'

export default function Footer(props) {
  return (
    <div className="bg-black mt-8 py-12 flex flex-col text-center text-white items-center justify-center">
        <Logo
            className="h-12"
            disabled
        />

        <a
          className="mt-2 font-bold"
          href="tel:+19186502928"
        >
          918-652-5587
        </a>

        <p className="font-bold">
            14502 S Lewis Ave, Bixby, OK 74008
        </p>

        <p className="font-bold mt-2 text-xs">
            COPYRIGHT 2023. ALL RIGHTS RESERVED. <br />
            STEADFAST MACHINE, LLC.
        </p>
    </div>
  )
}

Footer.propTypes = {}
