import React from 'react'
import PropTypes from 'prop-types'


export default function Contact(props) {
  return (
    <div>
        Contact Us
    </div>
  )
}

Contact.propTypes = {}
