import React from 'react'
import PropTypes from 'prop-types'

import ManualLathe from './Home/images/manual-lathe.png'

import SecondaryPage from '../Components/SecondaryPage'

const iframeSrc = 'https://www.youtube.com/embed/Gd-UqQksN3I'

const content = (
  <div>
    <p className="mb-4">
    Manual lathes offer versatility and flexibility, making them ideal for small-scale production, one-of-a-kind creations, and intricate designs. Manual mills excel in drilling, milling, and cutting, making them indispensable for custom projects and unique designs. 
    </p>

    <p className="mb-4">
      With meticulous attention to detail and a passion for excellence, our team ensures that each workpiece undergoes a seamless transformation from raw material to finished product. Whether it's a one-off masterpiece or a small production run, our manual mill services deliver outstanding results that stand the test of time.
    </p>
  </div>
)


export default function ManualLatheAndMill() {
  return (
    <div>
      <SecondaryPage
        banner={ ManualLathe }
        content={ content }
        video={ iframeSrc }
        title="Manual Lathe & Mill"
      />
    </div>
  )
}

ManualLatheAndMill.propTypes = {}
