import React from 'react'
import PropTypes from 'prop-types'

export default function LiveDemo(props) {
  return (
    <div className="bg-black pt-4 pb-8 px-4 mb-12 md:pt-24 md:pb-32">
      <div className="bg-black text-3xl text-white py-4 pr-6 font-semibold md:text-center md:pb-12">
        Learn more about our equipment
      </div>

      <div className="w-full overflow-x-auto flex md:justify-center">
        <div className="flex-shrink-0 overflow-hidden inline-block w-80 mr-4 md:w-1/3">
            <iframe
                className="w-full h-64 rounded-lg md:h-92"
                src="https://www.youtube.com/embed/vEBcMmbNuI4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>

        <div className="flex-shrink-0 overflow-hidden inline-block w-80 md:w-1/3">
            <iframe
                className="w-full h-64 rounded-lg"
                src="https://www.youtube.com/embed/LN_LYo84Oko"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
        </div>
    </div>
  )
}

LiveDemo.propTypes = {}
