import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'


export default function ServiceBlock({ src, serviceName, serviceDescription, to }) {
    return (
        <div className="flex flex-col items-center justify-center text-left w-5/6 md:w-1/4 mb-16">
            <Link
                to={ to }
            >
                <img
                    alt=""
                    className="rounded-md md:w-full md:h-60"
                    src={ src }
                />

                <div className="mt-2">
                    <p className="text-[#B39A5B] text-xl font-bold">{ serviceName }</p>
                    <p className="text-md text-left">{ serviceDescription }</p>
                </div>

                <div className="underline mt-4">
                    Learn More >
                </div>
            </Link >
        </div>
    )
}

ServiceBlock.propTypes = {}
