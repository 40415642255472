import React, { useState } from 'react'

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    message: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Process the form data or submit it to the backend
    console.log(formData)
  }

  return (
    <form
      id="contact-section"
      onSubmit={ handleSubmit }
      className="max-w-sm mx-auto p-4 rounded-lg shadow-md bg-[#B39A5B] text-black md:w-full"
    >
      <p className="text-3xl text-white mb-4 font-bcodold">
        Get In Touch
      </p>
      <div className="mb-4">
        <label
          htmlFor="name"
          className="block text-white font-bold mb-1"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={ formData.name }
          onChange={ handleChange }
          className="w-full px-3 py-2 border rounded-md"
          required
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="phoneNumber"
          className="block text-white font-bold mb-1"
        >
          Phone #
        </label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={ formData.phoneNumber }
          onChange={ handleChange }
          className="w-full px-3 py-2 border rounded-md"
          required
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="message"
          className="block text-white font-bold mb-1"
        >
          How can we help?
        </label>
        <textarea
          id="message"
          name="message"
          value={ formData.message }
          onChange={ handleChange }
          className="w-full px-3 py-2 border rounded-md"
          required
        ></textarea>
      </div>

      <button
        type="submit"
        className="w-full bg-black text-white font-bold py-2 px-4 rounded-md"
      >
        Submit
      </button>
    </form>
  )
}
