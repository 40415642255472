import React from 'react';
import HeroImg from '../images/haas-cnc.webp';
import { Link } from 'react-scroll';


export default function HeroBanner() {
  const screenWidth = window.innerWidth

  const buttonText = ( screenWidth > 700 ) ? "918-652-5587" : 'CALL NOW'

  return (
    <div className="bg-black pb-12">
      <div className="flex flex-col justify-center items-center pt-4 px-2 md:pt-8 md:px-16">
        <h1 className="text-center text-3xl md:text-5xl text-white font-black">
          PRECISION METAL FABRICATION EXPERTS
        </h1>

        <h2 className="text-center text-xl md:text-2xl text-white mt-4 mb-6 md:w-1/2">
          Steadfast Machine is an Industrial Machining & Metal Fabrication shop located in Tulsa, Oklahoma.
        </h2>

        <div className="flex flex-col items-center justify-center">
          <a
            className="w-full text-center bg-[#B39A5B] text-white py-4 px-6 mt-4 font-black rounded-md text-lg"
            href="tel:+19186502928"
          >
            { buttonText }
          </a>

          <Link
            activeClass="active"
            to="contact-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="border-[#B39A5B] border-2 text-white py-4 px-6 mt-4 font-black rounded-md text-lg"
          >
            REQUEST A QUOTE
          </Link>
        </div>
      </div>

      <div className="md:flex md:items-center md:justify-center">
        <img
          src={ HeroImg }
          className="w-full md:w-1/2"
          alt="Hero Banner"
        />
      </div>
    </div>
  );
}
