import React, {
    useEffect,
} from 'react'

import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import AS9100 from '../Pages/Home/components/AS9100'

import ContactForm from '../Pages/Home/components/ContactForm'
import Footer from '../Pages/Home/components/Footer'


export default function SecondaryPage({ banner, content, secondContent, title, video, secondVideo }) {
    const { pathname } = useLocation();

    useEffect(() => {
    // Scroll to the top of the page whenever the pathname changes (navigation occurs)
    window.scrollTo(0, 0)
    }, [pathname])

  return (
    <div>
        <div className="md:w-1/2 md:flex md:flex-col md:m-auto">
            <img
                src={ banner }
                className="w-full"
            />

            <div className="px-4">
                <h1 className="text-left text-3xl font-bold my-6">
                    { title }
                </h1>

                { content } 
            </div>

            <div className="my-8">
                <iframe
                    className="w-full h-64 md:h-96"
                    src={ video }
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>

            <div className="px-4 mb-12">
                { secondContent }
            </div>

            {
                renderSecondIframe({ secondVideo })
            }

            <ContactForm />

        </div>

        <div className="mt-8">
            <AS9100
                className="h-16"
            />
        </div>

        <Footer />        
    </div>
  )
}

SecondaryPage.propTypes = {}

function renderSecondIframe({ secondVideo }) {
    if ( !secondVideo ) return null

    return (
        <div className="my-8">
            <iframe
                className="w-full h-64 md:h-screen"
                src={ secondVideo }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
    )
}
