import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import Root from './Pages/Root'

import Contact from './Pages/Contact'
import Home from './Pages/Home/Home'

// Secondary Pages
import CNCLatheAndMill from './Pages/CNCLatheAndMill'
import ManualLatheAndMill from './Pages/ManualLatheAndMill'
import MigAndStickWelding from './Pages/MigAndStickWelding'

import ErrorPage from './Pages/ErrorPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'cnc-lathe-and-mill',
        element: <CNCLatheAndMill />,
      },
      {
        path: 'manual-lathe-and-mill',
        element: <ManualLatheAndMill />,
      },
      {
        path: 'welding',
        element: <MigAndStickWelding />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider
      router={ router }
    />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
