import React from 'react'
import PropTypes from 'prop-types'

import ServiceBlock from './ServiceBlock'

import HaasGIF from '../images/haas-gif.webp'
import ManualLathe from '../images/manual-lathe.png'
import PressBrakeGIF from '../images/press-brake.gif'
import WeldingGIF from '../images/welding.gif'


export default function OurServices() {
  return (
    <div className="bg-[#f4f4f4] py-8">
        <div className="flex flex-col text-center mb-8">
            <h2 className="text-3xl text-[#B39A5B] font-bold">Our Services</h2>
        </div>

        <div className="flex flex-col items-center justify-center md:flex-row md:justify-around">
            <ServiceBlock
                serviceDescription="We use 8 CNC machines, 3 and 4-axis machines, and CNC lathes with live tooling capabilities."
                serviceName="CNC Lathe & Mill"
                src={ HaasGIF }
                to="/cnc-lathe-and-mill"
            />

            <ServiceBlock
                serviceDescription="Our state-of-the-art equipment ensures durable and high-quality welds that meet your specifications."
                serviceName="Welding"
                src={ WeldingGIF }
                to="/welding"
            />

            <ServiceBlock
                serviceDescription="Our skilled machinists meticulously shape and craft each workpiece by hand, ensuring precision and attention to detail for one-of-a-kind creations and small-scale productions."
                serviceName="Manual Lathe & Mill"
                src={ ManualLathe }
                to="/manual-lathe-and-mill"
            />
        </div>
    </div>
  )
}

OurServices.propTypes = {}
