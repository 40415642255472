import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import LogoImg from '../images/logo-standalone-no-bg.png'


export default function Logo({ className, disabled }) {
  if ( disabled ) {
    return (
      <img
          alt="Steadfast Machine Logo"
          className={ className }
          src={ LogoImg }
      />
    )
  }

  return (
    <Link
        to='home'
    >
        <img
            alt="Steadfast Machine Logo"
            className={ className }
            src={ LogoImg }
        />
    </Link>
  )
}

Logo.propTypes = {}
